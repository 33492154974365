import { Forage }   from ".";
import { ModeType } from "../../";

export async function getTheme(): Promise<ModeType> {
	return await Forage.getItem("theme.mode");
}

export async function getMenuState(): Promise<boolean> {
	return await Forage.getItem("menu.state");
}

export async function getAuthToken(): Promise<string> {
	return await Forage.getItem("auth.token");
}

export async function setAuthToken(token: string): Promise<string> {
	return await Forage.setItem("auth.token", token);
}