import React                                                     from "react";
import { ThemeContext, ThemeContextType, BaseComponentPropType } from "..";
import { CX, Styles }                                            from "./styles";

/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export function Footer(props: BaseComponentPropType<HTMLDivElement>) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	const classes = CX(
		Styles.footer,
		Styles[themeContext.breakpoint],
		props.className
	);

	// Create new mutable props object to delete unwanted props for prettier attributes
	const $props: BaseComponentPropType<HTMLDivElement> = Object.create(props);
	delete $props.padding;

	return (
		<div
			{...$props}
			style={{...$props.style}}
			className={classes}
		>
			<p className={CX(Styles[themeContext.mode])}>DigiTrakAdmin @ 2023 | v1.0.1-alpha</p>
			{props.children}
		</div>
	);
}