import React                              from "react";
import { CX, Styles }                     from "./styles";
import { ThemeContext, ThemeContextType } from "../../../Components/Theme";
import { Button, Text }                   from "../../../Components";
import { BsXLg }                          from "react-icons/bs";
import { EventContextType }               from "../types";
import { EventContext, useEvent }         from "../component";
import { EventItemPropType }              from "./types";

export const EventItem = (props: EventItemPropType) => {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext)
	const eventContext = React.useContext<EventContextType>(EventContext);
	const event = useEvent();
	const [active, setActive] = React.useState<string>("")
	const [leave, setLeave] = React.useState<string>("");
	const [disabled, setDisabled] = React.useState<boolean>(false);
	const activeTimerRef = React.useRef(null);
	const deleteTimerRef = React.useRef(null);
	const expiresTimerRef = React.useRef(null);

	React.useEffect(() => {
		if ((eventContext.value.findIndex((element) => element.id === props.id) === 0) && !document.getElementById(props.id).classList.contains("active")) {
			setActive(CX(Styles["active"]));
			const queuedElem = document.getElementById(eventContext.value[0].id);

			queuedElem.style.bottom = null;

			if (props.expiresIn) {
				expiresTimerRef.current = setTimeout(() => {
					closeHandler();
				}, props.expiresIn)
			}
		} else if (((eventContext.value.findIndex((element) => element.id === props.id) === 1) && !document.getElementById(props.id).classList.contains("active-queued"))) {
			const queuedElem = document.getElementById(eventContext.value[1].id);
			const y = queuedElem.offsetHeight;

			queuedElem.style.bottom = `${-y - 8}px`;
			queuedElem.classList.add(CX(Styles["active-queued"]));
			setActive(CX(Styles["active-queued"]));
		}
	}, [eventContext.value])

	function closeHandler() {
		setLeave(CX(Styles["leave"]))
		if (eventContext.value.length > 1) {
			setTimeout(() => {
				document.getElementById(eventContext.value[1].id).classList.add(CX(Styles["active"]));
				document.getElementById(eventContext.value[1].id).classList.remove(CX(Styles["active-queued"]));

				const queuedElem = document.getElementById(eventContext.value[1].id);
				queuedElem.style.bottom = null;

				if (eventContext.value.length > 2) {
					const queuedElem = document.getElementById(eventContext.value[2].id);
					const y = queuedElem.offsetHeight;
					queuedElem.style.bottom = `${-y - 8}px`;
					queuedElem.classList.add(CX(Styles["active-queued"]));
				}
			}, 200);
		}
		deleteTimerRef.current = setTimeout(() => {
			event({
				action: "remove",
				options: {
					eventTitle: props.eventTitle,
					id: props.id
				}
			})
		}, 300)
	}

	// garbage clean up
	React.useEffect(() => {
		return () => {
			clearTimeout(activeTimerRef.current);
			clearTimeout(deleteTimerRef.current);
			clearTimeout(expiresTimerRef.current);
		}
	}, [])

	return (

		<div
			id={props.id}
			key={props.id}
			className={CX(
				Styles["item"],
				Styles[themeContext.mode],
				active,
				leave,
				disabled && Styles["disabled"]
			)}
		>
			<div className={CX(Styles["content-wrapper"])}>
				<div className={CX(Styles["content"])}>
					<div className={CX(Styles["header"])}>
						{
							props.eventIcon &&
                            <div className={CX(Styles["event-icon"], Styles[themeContext.mode])}>
								{props.eventIcon}
                            </div>
						}
						<Text>{props.eventTitle ?? "Event"}</Text>
						<div>
							<Button
								buttonType={"secondary"}
								variant={"transparent"}
								size={"sm"}
								className={CX(Styles[""])}
								disabled={disabled}
								style={{padding: "0.5rem 0.5rem"}}
								onClick={closeHandler}
							>

								<BsXLg size={12} className={CX(Styles["event-close"], Styles[themeContext.mode])}/>
							</Button>
						</div>
					</div>

					<div className={CX(Styles["event-children"])}>
						{props.children}
					</div>
				</div>
			</div>
		</div>
	)
}

export default EventItem