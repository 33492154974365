import React                              from "react";
import { ThemeContext, ThemeContextType } from "../../";
import { HeaderComponentPropType }        from ".";
import { CX, Styles }                     from "./styles";

/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export function Header( props: HeaderComponentPropType ) {
    const themeContext = React.useContext<ThemeContextType>( ThemeContext );

    const classes = CX( Styles[themeContext.mode], Styles.header, props.underline ? Styles.underline : null, props.className );

    // Create new mutable props object to delete unwanted props for prettier attributes
    const $props: HeaderComponentPropType = Object.assign( {}, props );
    delete $props.padding;
    delete $props.background;
    delete $props.color;
    delete $props.weight;
    delete $props.size;
    delete $props.underline;

    return (
        <h1
            { ...$props }
            className={ classes }
            style={
                {
                    padding: props.padding,
                    background: props.background,
                    color: props.color,
                    fontWeight: props.weight,
                    fontSize: typeof props.size === "object" ? props.size[themeContext.breakpoint] : props.size,
                    ...props.style
                }
            }
        >
            { props.children }
        </h1>
    );
}