import React                                                                        from "react";
import { EventContextType, EventProviderPropType, EventAction, EventActionOptions } from "./types";
import type { Context }                                                             from "react";
import { v4 as uuid }                                                               from "uuid";
import { EventItem }                                                                from "./EventItem";
import { ThemeContext, ThemeContextType }                                           from "../Theme";
import { CX, Styles }                                                               from "./styles";

export const EventContext: Context<EventContextType> = React.createContext<EventContextType>({
	value: [],
	set: null,
	position: "bottom-right",
	eventSoftCap: 4
})

/**
 * Event Context Provider. Must wrap any components that use the "useEvent" hook.
 */
export function EventProvider(props: EventProviderPropType) {
	// const themeContext = React.useContext<ThemeContextType>( ThemeContext );
	const [state, dispatch] = React.useReducer((state: Array<EventActionOptions>, action: EventAction) => {
		const id = uuid();
		switch (action.action) {
			case "add":
				return state.concat({...action.options, id: id || action.options.id});
			case "remove":
				return state.filter(item => item.id !== action.options.id);
			default:
				throw new Error();
		}
	}, []);

	// Delete unrecognized props
	const classes = CX(Styles["event-container"])
	const divProps = Object.assign({}, props);
	delete divProps.position;

	return (
		<EventContext.Provider
			value={{
				value: state,
				set: dispatch,
			}}
		>
			{props.children}
			<div
				// className={ `event-container ${ props.position || "bottom-right" } ${ themeContext.mode }` }
				className={classes}
			>
				{state.map((item: EventActionOptions) => (
					<EventItem
						id={item.id}
						key={item.id}
						children={item.children}
						eventIcon={item.eventIcon}
						eventTitle={item.eventTitle}
						eventColor={item.eventColor}
						expiresIn={item.expiresIn}
					/>
				))}
			</div>
		</EventContext.Provider>
	);
}

/**
 * Event Hook
 */
export const useEvent = () => {
	const dispatch = React.useContext(EventContext);

	return (event: EventAction) => {
		dispatch.set(event);
	};
}

/**
 * Get Event Items Hook
 */
export const useEventItems = () => {
	const items = React.useContext(EventContext).value;

	return items;
}