import React                                          from "react";
import type { Context }                               from "react";
import type { AuthContextType, AuthProviderPropType } from ".";
import { Log, API }                                   from "../../";
import cookie                                         from "js-cookie";

export const AuthContext: Context<AuthContextType> = React.createContext<AuthContextType>({
	authenticated: null,
	setAuthenticated: null,
	data: null,
	validate: null,
	setValidate: null,
});

export const AuthProvider = (props: AuthProviderPropType) => {
	const [authenticated, setAuthenticated] = React.useState<boolean>(null);
	const [data, setData] = React.useState(null);
	const [validate, setValidate] = React.useState(false);

	// Validates local token to ensure it is not invalid
	async function validateCookieToken() {
		console.log("validating...");
		Log("[ Auth ] : Validation of cookie token started...");

		Log("[ Auth ] : Checking cookies for token...");
		// Check if there is a token stored in local storage
		const token = cookie.get("token");
		console.log(token);
		if (token) {
			Log("[ Auth ] : Token was found in cookies.");
			const isVerified = await API.VerifyToken();
			console.log(isVerified);
			if (isVerified.status === 200) {
				const data = await isVerified.json();
				console.log(data);
				setData(data);
				setAuthenticated(true);
				Log("[ Auth ] : Token is valid.");
			} else {
				setAuthenticated(false);
				console.log("invalid...");
				Log("[ Auth ] : Token is invalid.");
			}
		} else {
			console.log("no token found...");
			Log("[ Auth ] : Token was not found in cookies.");
			setAuthenticated(false);
			Log("[ Auth ] : No token found.");
		}
	}

	// Initial Render Hook
	React.useEffect(() => {
		validateCookieToken().then(() => Log("[ Auth ] : Finished validation of cookie token."));
	}, [validate]);

	return (
		<AuthContext.Provider
			value={{
				authenticated: authenticated,
				setAuthenticated: setAuthenticated,
				data: data,
				validate: validate,
				setValidate: setValidate,
			}}
			children={props.children}
		/>
	);
};