import React                              from "react";
import { ThemeContext, ThemeContextType } from "..";
import { CX, Styles }                     from "./styles";
import type { BoxComponentPropType }      from ".";

/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export function Box(props: BoxComponentPropType) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	const classes = CX(
//        Styles[themeContext.mode],
		Styles.box,
		Styles[themeContext.breakpoint],
		props.disableHorizontalPadding ? Styles["disable-horizontal-padding"] : null,
		props.disableVerticalPadding ? Styles["disable-vertical-padding"] : null,
		props.disabled ? Styles.disabled : null,
		props.className);

	// Create new mutable props object to delete unwanted props for prettier attributes
	const $props: BoxComponentPropType = Object.create(props);
	delete $props.padding;
	delete $props.disableHorizontalPadding;
	delete $props.disableVerticalPadding;

	return (
		<div
			{...$props}
			className={classes}
			style={
				{
					padding: props.padding,
					...props.style
				}
			}
		>
			{props.children}
		</div>
	);
}