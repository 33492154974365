import React                                                                              from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Navigate } from "react-router-dom";
import { AuthContext, AuthShell, PublicShell, Spinner }                                   from "./Components";
import type { AuthContextType }                                                           from "./Components";

// Lazy load routes
// Auth-agnostic
const Error404 = React.lazy(() => import("./Routes/404"));
// Public
const Login = React.lazy(() => import("./Routes/login"));
// Private
const Dashboard = React.lazy(() => import("./Routes/dashboard"));
const BugReports = React.lazy(() => import("./Routes/bug-reports"));
const Logs = React.lazy(() => import("./Routes/logs"));
const Accounts = React.lazy(() => import("./Routes/accounts"));
const ManageAccount = React.lazy(() => import("./Routes/accounts/manage"));
const ConfirmRequest = React.lazy(() => import("./Routes/confirm"))
const Admins = React.lazy(() => import("./Routes/admins"));
const CreatePassword = React.lazy(() => import("./Routes/create-password"));

// This is the recommended router for all React Router web projects. It uses the DOM History API to update the URL and
// manage the history stack.
// It also enables the react-router v6.4 data APIs like loaders, actions, fetchers and more.
function Router() {
	const authContext: AuthContextType = React.useContext<AuthContextType>(AuthContext);

	return (
		createBrowserRouter(
			createRoutesFromElements(
				// Render routes based on authentication context boolean
				authContext.authenticated === null

					// Auth validation is not complete (avoids flickering)
					? 
						<>
							<Route path={"*"} element={<Spinner/>}/>
						</>
					// Auth validation has completed
					: authContext.authenticated === true

						// Authenticated routes
						// These routes should never be rendered unless context validates a locally stored token (in our case, IndexedDB)
						? (<>
								<Route element={<AuthShell/>}>
									<Route path={"/"} element={<Navigate to="/dashboard" replace/>}/>
									<Route path={"/dashboard"} element={<Dashboard/>}/>
									<Route path={"/bug-reports"} element={<BugReports/>}/>
									<Route path={"/accounts"} element={<Accounts/>}/>
									<Route path={"/accounts/manage"} element={<ManageAccount/>}/>
									<Route path={"/logs"} element={<Logs/>}/>
									<Route path={"/confirm-request"} element={<ConfirmRequest/>}/>
									<Route path={"/create-password"} element={<CreatePassword/>}/>
									<Route path={"/admins"} element={<Admins/>}/>

									<Route path={"/404"} element={<Error404/>}/>

									{/* Wildcard route */}
									{/* If no route is found, this route will redirect to a 404 page */}
									<Route path="*" element={<Navigate to="/404" replace/>}/>
								</Route>
							</>
						)

						: authContext.authenticated === false
							// Public routes
							// These routes will be rendered if authentication does not validate local token
							? <Route element={<PublicShell/>}>
								<Route path={"/login"} element={<Login/>}/>
								<Route path={"/"} element={<Login/>}/>
								<Route path={"/confirm-request"} element={<ConfirmRequest/>}/>
								<Route path={"/create-password"} element={<CreatePassword/>}/>

								<Route path={"/404"} element={<Error404/>}/>

								{/* Wildcard route */}
								{/* If no route is found, this route will redirect to a 404 page */}
								<Route path="*" element={<Navigate to="/404" replace/>}/>
							</Route>
							: <Spinner/>
			)
		)
	)
}

export default function Routing() {
	return (
		<RouterProvider router={Router()}/>
	);
}
export { Routing };