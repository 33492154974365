import React                                             from "react";
import { ThemeContext, ThemeContextType, useBreakpoint } from "..";
import type { ColPropType, RowPropType }                 from ".";
import { CX, Styles }                                    from "./styles";
import { v4 as uuid }                                    from "uuid";

/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export const Row = (props: RowPropType) => {
    const themeContext = React.useContext<ThemeContextType>(ThemeContext);

    const classes = CX(Styles.row, props.className, Styles[themeContext.mode]);

    // Delete unrecognized props
    const $props = Object.assign({}, props);
    delete $props.alignItems;
    delete $props.columnGap;

    return (
        <div
            {...$props}
            className={classes}
            style={{
                ...props.style
            }}
        />
    );
};

export const Col = (props: ColPropType) => {
    const themeContext = React.useContext<ThemeContextType>(ThemeContext);
    const [id, setId] = React.useState(uuid());

    const classes = CX(
        Styles.col,
        props.hasPadding ? Styles.padding : null,
        props.className,
        Styles[themeContext.mode]
    );

    // Delete unrecognized props
    const {extends: colExtends, hasPadding, ...$props} = props;
    // delete $props.extends;
    // delete $props.hasPadding;

    /***
     * Column maximum extend
     * @type {number}
     */
    const colMax: number = Number.parseInt(window.getComputedStyle(document.body, ":after").content.replaceAll("\"", ""));
    
    /***
     * For width calculation/setting of Cols
     */
    React.useEffect(() => {
        const elem = document.getElementById(props.id || id);
        let width = "";
        if (typeof props.extends === "object") {
            width = ((props.extends[themeContext.breakpoint] / colMax) * 100) + "%";
        } else {
            width = ((props.extends / colMax) * 100) + "%";
        }
        if (elem) {
            elem.style.width = width;
            elem.style.opacity = "1";
            // elem.style.pointerEvents = "all";
        }
    }, [themeContext.breakpoint, null]);

    return (
        <div
            {...$props}
            className={classes}
            id={props.id || id}
            style={{
                ...props.style
            }}
        />
    );
};