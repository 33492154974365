import { GridRowId } from "@mui/x-data-grid";

export function generateAPIBaseURL(endpoint: string) {
	const url = process.env["REACT_APP_ENVIRONMENT"].toLowerCase() === "development" ? "http://localhost:8080" : "https://" + process.env["REACT_APP_API_URL"];
	return url + (endpoint[0] !== "/" ? "/" : "") + endpoint;
}

/***
 * @constructor
 */
export async function VerifyToken() {
	return await fetch(generateAPIBaseURL(`/auth/verify`), {
		credentials: "include"
	});
}

/***
 * @constructor
 */
export async function GetOrgs() {
	return await fetch(generateAPIBaseURL(`/org`), {
		credentials: "include"
	});
}

/***
 * @constructor
 */
export async function GetNumAccounts() {
	return await fetch(generateAPIBaseURL(`/accounts/count`), {
		credentials: "include"
	});
}

/***
 * @constructor
 */
export async function Login(email: string, password: string) {
	return await fetch(generateAPIBaseURL(`/auth/login`), {
		credentials: "include",
		body: JSON.stringify({
			email: email,
			password: password
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}


/***
 * @constructor
 */
export async function VerifyOtp(email: string, otp: string) {
	return await fetch(generateAPIBaseURL(`/auth/verify-otp`), {
		credentials: "include",
		body: JSON.stringify({
			email: email,
			otp: otp
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}

/***
 * @constructor
 */
export async function CreateAccount(email: string, password: string) {
	return await fetch(generateAPIBaseURL(`/auth/create-account`), {
		credentials: "include",
		body: JSON.stringify({
			email: email,
			password: password
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}

/***
 * @constructor
 */
export async function CreateOrg(name: string, uniqueName: string, color: string, owner: string) {
	return await fetch(generateAPIBaseURL(`/org/create`), {
		credentials: "include",
		body: JSON.stringify({
			name: name,
			uniqueName: uniqueName,
			color: color,
			owner: owner
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}

export async function ApproveOrRejectOrg(organizations: Array<GridRowId>, action: "approve" | "reject") {
	console.log(organizations, action)
	return await fetch(generateAPIBaseURL(`/org/approve`), {
		credentials: "include",
		body: JSON.stringify({
			organizations: organizations,
			action: action
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}

export async function ActivateOrDeactivateOrg(organizations: Array<GridRowId>, action: "activate" | "deactivate") {
	console.log(organizations, action)
	return await fetch(generateAPIBaseURL(`/org/active`), {
		credentials: "include",
		body: JSON.stringify({
			organizations: organizations,
			action: action
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}

/***
 * @constructor
 */
export async function FetchLogs(to?: number, from?: number) {
	return await fetch(generateAPIBaseURL(`/logs/fetch-all`), {
		credentials: "include",
		body: JSON.stringify({
			to,
			from
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}

/***
 * @constructor
 */
export async function FetchReports(to?: number, from?: number) {
	return await fetch(generateAPIBaseURL(`/bug-reports/fetch-all`), {
		credentials: "include",
		body: JSON.stringify({
			to,
			from
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}

/***
 * @constructor
 */
export async function EditReport(reportId: string, orgId: string, status: "Resolved" | "Open") {
	return await fetch(generateAPIBaseURL(`/bug-reports/edit`), {
		credentials: "include",
		body: JSON.stringify({
			reportId,
			orgId,
			status
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}

/***
 * @constructor
 */
export async function FetchAccounts() {
	return await fetch(generateAPIBaseURL(`/accounts/fetch-all`), {
		credentials: "include",
		headers: [
			["Content-Type", "application/json"]
		],
		method: "get"
	});
}

/*** 
* @constructor
*/
export async function FetchAccount(id: string) {
   return await fetch(generateAPIBaseURL(`/accounts/fetch`), {
	   credentials: "include",
	   body: JSON.stringify({
			id: id,
	   }),
	   headers: [
		   ["Content-Type", "application/json"]
	   ],
	   method: "post"
   });
}

/*** 
* @constructor
*/
export async function RequestImpersonatation(id: string) {
	return await fetch(generateAPIBaseURL(`/accounts/request-impersonation`), {
		credentials: "include",
		body: JSON.stringify({
			id: id,
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}

/*** 
* @constructor
*/
export async function Impersonate(id: string) {
	return await fetch(generateAPIBaseURL(`/accounts/impersonation`), {
		credentials: "include",
		body: JSON.stringify({
			id: id,
		}),
		headers: [
			["Content-Type", "application/json"]
		],
		method: "post"
	});
}


/*** 
* @constructor
*/
export async function ConfirmRequest(token: string) {
	return await fetch(generateAPIBaseURL(`/accounts/confirm-request`), {
		credentials: "include",
		body: JSON.stringify({
			token: token
		}),
		headers: [
			["Content-Type", "application/json"],
		],
		method: "post"
	});
}


/***
 * @constructor
 */
export async function FetchAdminUsers() {
	return await fetch(generateAPIBaseURL(`/users/fetch-all`), {
		credentials: "include",
		headers: [
			["Content-Type", "application/json"]
		],
		method: "get"
	});
}


/*** 
* @constructor
*/
export async function CreatePassword(token: string, password: string) {
	return await fetch(generateAPIBaseURL(`/users/create-password`), {
		credentials: "include",
		body: JSON.stringify({
			token: token,
			password: password
		}),
		headers: [
			["Content-Type", "application/json"],
		],
		method: "post"
	});
}

export async function CreateUser(user: Partial<{email: string, super: boolean}>) {
	return await fetch(generateAPIBaseURL(`/users/create`), {
		credentials: "include",
		body: JSON.stringify({
			user: user
		}),
		headers: [
			["Content-Type", "application/json"],
		],
		method: "post"
	});
}

export async function DeleteUser(ids: Array<GridRowId>) {
	return await fetch(generateAPIBaseURL(`/users/delete`), {
		credentials: "include",
		body: JSON.stringify({
			ids: ids
		}),
		headers: [
			["Content-Type", "application/json"],
		],
		method: "post"
	});
}

export async function EditUser(user: any) {
	return await fetch(generateAPIBaseURL(`/users/edit`), {
		credentials: "include",
		body: JSON.stringify({
			user
		}),
		headers: [
			["Content-Type", "application/json"],
		],
		method: "post"
	});
}