import type { BreakpointsType } from "../../";

/***
 * Get the breakpoint (xs, sm, md, lg, xl) from :before body
 * DEPRECATED
 * @returns {string}
 */
export const getBreakpoint = (): BreakpointsType => {
    return window.getComputedStyle( document.body, ":before" ).content.replaceAll( "\"", "" ) as BreakpointsType;
};
