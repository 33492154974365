import React                                  from "react";
import { ThemeContext, invertTheme, Spinner } from "..";
import type { ThemeContextType, ModeType }    from "..";
import { ButtonComponentPropType }            from ".";
import { CX, Styles }                         from "./styles";
import { v4 as uuid }                         from "uuid";

/***
 * Button
 * @param {ButtonComponentType} props
 * @returns {JSX.Element}
 * @constructor
 */
export function Button(props: ButtonComponentPropType): JSX.Element {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	const $mode: ModeType = props.overrideMode ?? (props.invertMode ? invertTheme(themeContext.mode) : themeContext.mode);

	const id = React.useRef(props.id ?? "no-id")

	const classes = CX(
		Styles.btn,
		Styles[props.focused ? "focused" : ""],
		props.className,
		props.disabled || props.loading === true ? Styles.disabled : null,
		Styles[props.size ?? "md"],
		Styles[props.buttonType ?? "primary"],
		Styles[props.variant ?? "filled"],
		Styles[$mode]
	);

	// Create new mutable props object to delete unwanted props for prettier attributes
	const $props: ButtonComponentPropType = Object.assign({}, props);
	delete $props.loading;
	delete $props.variant;
	delete $props.disableRipple;
	delete $props.buttonType;
	delete $props.header;
	delete $props.icon;
	delete $props.toggle;
	delete $props.invertMode;
	delete $props.overrideMode;
	delete $props.focused;
	delete $props.rippleClassName;

	const rippleHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		const circle = document.createElement("div");
		circle.classList.add(CX(Styles.circle));

		if (props.rippleClassName) {
			circle.classList.add(props.rippleClassName);
		}

		circle.classList.add("circle");
		e.currentTarget.appendChild(circle);

		const buttonBounds = e.currentTarget.getBoundingClientRect();

		const diameter = Math.max(e.currentTarget.clientWidth, e.currentTarget.clientHeight);
		const radius = diameter / 2;

		const newX = e.clientX - buttonBounds.left - (radius);
		const newY = e.clientY - buttonBounds.top - (radius);
		circle.style.width = (radius * 2) + "px";
		circle.style.height = (radius * 2) + "px";
		circle.style.left = newX + "px";
		circle.style.top = newY + "px";

		circle.classList.add(CX(Styles.active));
		circle.classList.add(CX(Styles.expand));

		if (!props.toggle) {
			setTimeout(() => circle.classList.remove(CX(Styles.active)), 475);
			setTimeout(() => {
				circle.remove(); // Remove from DOM for optimizations
			}, 1500);
		}
	};

	function generateFocusRipple(id) {
		const circle = document.createElement("div");
		const elem = document.getElementById(id);
		circle.classList.add(CX(Styles.circle));
		circle.classList.add(CX(Styles["disable-ripple-animation"]));
		circle.classList.add("circle");
		elem.appendChild(circle);

		const diameter = Math.max(elem.clientWidth, elem.clientHeight);
		const radius = diameter / 2;

		circle.style.width = (radius * 2) + "px";
		circle.style.height = (radius * 2) + "px";

		circle.classList.add(CX(Styles.active));
		circle.classList.add(CX(Styles.expand));
	}

	function hasRipple(e: React.MouseEvent<HTMLButtonElement>) {
		const ripples = e.currentTarget.querySelector(".circle");
		return !!ripples;
	}

	function hasRippleById(id: string) {
		const ripples = document.getElementById(id).querySelector(".circle");
		return !!ripples;
	}

	function rippleRemover(e: React.MouseEvent<HTMLButtonElement>) {
		const ripple = e.currentTarget.querySelector(".circle");
		ripple.classList.remove(CX(Styles.active));
		ripple.classList.remove("circle");
		setTimeout(() => {
			ripple.remove(); // Remove from DOM for optimizations
		}, 300);
	}

	function rippleRemoverById(id: string) {
		if (hasRippleById(id)) {
			const ripple = document.getElementById(id).querySelector(".circle");
			ripple.classList.remove(CX(Styles.active));
			ripple.classList.remove("circle");
			setTimeout(() => {
				ripple.remove(); // Remove from DOM for optimizations
			}, 300);
		}
	}

	// React.useEffect(() => {
	// 	if (props.toggle) {
	// 		if (props.focused === false) {
	// 			rippleRemoverById(id.current);
	// 		}
	// 	}
	// }, [props.focused]);
	//
	// React.useEffect(() => {
	// 	if (props.toggle) {
	// 		if (props.focused === false) {
	// 			rippleRemoverById(id.current);
	// 		} else if (props.focused === true) {
	// 			generateFocusRipple(id.current);
	// 		}
	// 	}
	// }, []);

	return (
		<button
			{...$props}
			className={classes}
			id={id.current}
			onClick={(e) => {
				if (!props.disableRipple) {
					if (props.toggle) {
						if (!hasRipple(e)) {
							rippleHandler(e);
						} else {
							if (!props.focused) {
								rippleRemover(e);
							}
						}
					} else {
						rippleHandler(e);
					}
				}
				if (props.onClick) {
					props.onClick(e);
				}
			}
			}
		>
			{/* Icon */}
			{
				props.loading
					? <Spinner size={16} className={CX(Styles["spinner"], Styles[themeContext.mode])} relative/>
					: props.icon
						? <div className={CX(Styles.icon)}>
							{props.icon}
						</div>
						: null
			}

			{/* Children */}
			{props.children}
		</button>
	);
}