import React                                              from "react";
import type { Context }                                   from "react";
import type { ThemeContextType, ModeType, ThemePropType } from ".";
import type { BreakpointsType }                           from "../../";
import { CX, Styles }                                     from "./style";
import { LocalStorage, useBreakpoint }                    from "../../";
import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material";

export const ThemeContext: Context<ThemeContextType> = React.createContext<ThemeContextType>({
	mode: "light",
	setMode: null,
	breakpoint: null,
	menu: null,
	setMenu: null,
	shelf: null,
	setShelf: null
});

export const ThemeProvider = (props: ThemePropType) => {
	const [mode, setMode] = React.useState<ModeType>();
	const [menu, setMenu] = React.useState<boolean>(null);
	const [shelf, setShelf] = React.useState<React.ReactNode>(null);
	const breakpoint: BreakpointsType = useBreakpoint();

	// MUI Theme
	const theme = createTheme({
		typography: {
			fontFamily: [
				"IBM Plex Sans",
				"sans-serif"
			].join(","),
			// body1: {
			// 	fontSize: 14
			// }
		},
		palette: {
			primary: {
				main: "#564CD9",
			},
			mode: mode,
			background: {
				paper: mode === "dark" ? "#202020" : "#FFFFFF"
			}
		},
		components: {
			MuiAutocomplete: {
				styleOverrides: {
					// root: {
					// 	'& label': {
					// 		fontSize: 22,
					// 	},
					// },
					// input: {
					// 	fontSize: 22,
					// },
					listbox: {
						fontSize: 14,
					},
				},
			},
		},
	});

	/***
	 * Handles predefined theme preferences (mode)
	 * @returns {Promise<void>}
	 */
	async function themeHandler(): Promise<void> {
		LocalStorage.getTheme().then($ => {
			if ($) setMode($);
		});
	}

	async function menuHandler(): Promise<void> {
		LocalStorage.getMenuState().then($ => {
			setMenu($ ?? true);
		});
	}

	/***
	 * Initial Render Hook
	 */
	React.useEffect(() => {
		if (!mode) {
			themeHandler().finally();
		}

		if (!menu) {
			menuHandler().finally();
		}
	}, []);

	/***
	 * Mode Change Hook
	 */
	React.useEffect(() => {
		if (mode) {
			LocalStorage.Forage.setItem("theme.mode", mode).finally();

			const classes = CX(Styles[mode]);

			// Wipe <body> classes and then add the theme.mode class
			document.body.className = "";
			document.body.classList.add(classes);
		}
	}, [mode]);

	/***
	 * Menu Change Hook
	 */
	React.useEffect(() => {
		if (menu !== null) {
			LocalStorage.Forage.setItem("menu.state", menu).finally();
		}
	}, [menu]);

	return (
		<MUIThemeProvider theme={theme}>
			<ThemeContext.Provider
				value={{
					mode: mode,
					setMode: setMode,
					breakpoint: breakpoint,
					menu: menu,
					setMenu: setMenu,
					shelf: shelf,
					setShelf: setShelf
				}}
				children={props.children}
			/>
		</MUIThemeProvider>
	);
};