import React                                                                              from "react";
import { CX, Styles }                                                                     from "./styles";
import type { ShelfComponentPropType }                                                    from ".";
import { useBreakpoint, useWindowSize, useShellDimensions, ThemeContext, Button, Header } from "..";
import { ResizableBox, ResizeCallbackData }                                               from "react-resizable";
import { BsXLg }                                                                          from "react-icons/bs";

/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export function ShelfV2(props: ShelfComponentPropType) {
	const themeContext = React.useContext(ThemeContext);
	const breakpoint = useBreakpoint();
	const windowSize = useWindowSize();
	const shellDimensions = useShellDimensions();

	const [height, setHeight] = React.useState<number>(0);
	const [open, setOpen] = props.open;

	const classes = CX(
		Styles.shelf,
		Styles[themeContext.mode],
		props.open[0] ? Styles.active : null,
		Styles[breakpoint],
		props.className);

	// Create new mutable props object to delete unwanted props for prettier attributes
	// const $props: ShelfComponentPropType = Object.create(props);
	// delete $props.open;

	function contentResizeHandler(e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) {
		const contentNewHeight = window.innerHeight - shellDimensions.nav.height - shellDimensions.breadcrumbs.height - data.size.height;
		const contentElement = document.getElementById("content");
		if (contentElement) contentElement.style.height = contentNewHeight + "px";
	}

	React.useEffect(() => {
		const contentElement = document.getElementById("content");
		let contentNewHeight = 0;
		if (open === true) {
			contentNewHeight = window.innerHeight - shellDimensions.nav.height - shellDimensions.breadcrumbs.height - height;
		} else if (open === false) {
			contentNewHeight = window.innerHeight - shellDimensions.nav.height - shellDimensions.breadcrumbs.height;
		}
		if (contentElement) contentElement.style.height = contentNewHeight + "px";
	}, [open, windowSize.width])

	React.useEffect(() => {
		setTimeout(() => {
			setHeight(windowSize.height * .6);
		}, 300);

		return () => {
			const contentElement = document.getElementById("content");
			if (contentElement) contentElement.style.height = window.innerHeight - shellDimensions.nav.height - shellDimensions.breadcrumbs.height + "px";
		}
	}, [open, windowSize.height])

	return (
		<ResizableBox
			width={windowSize.width - shellDimensions.menu.width}
			height={height ?? windowSize.height * 0.6}
			minConstraints={[windowSize.width, windowSize.height * 0.1]}
			maxConstraints={[windowSize.width - shellDimensions.menu.width, windowSize.height - 100]}
			axis={"y"}
			resizeHandles={["n"]}
			onResize={contentResizeHandler}
			onResizeStop={(e, data) => setHeight(data.size.height)}
			handle={(resizeHandle, ref) => {
				return <div ref={ref} className={CX(Styles.handle, Styles[themeContext.mode])}/>
			}}
			className={classes}
		>
			<div className={CX(Styles.container)}>
				<div className={CX(Styles.header)}>
					<Button
						onClick={() => props.open[1](false)}
						buttonType={"secondary"}
						variant={"transparent"}
						size={"sm"}
						className={CX(Styles["shelf-exit"])}>
						<BsXLg size={14}/>
					</Button>
				</div>
				<div className={CX(Styles.content)}>
					{props.children}
				</div>
			</div>
		</ResizableBox>
	);
}