import React            from "react";
import { ThemeContext } from "../../";

type ShellDimensionsLiteralType = {
	height: number;
	width: number;
}

type ShellDimensionsType = {
	nav: ShellDimensionsLiteralType
	content: ShellDimensionsLiteralType
	menu: ShellDimensionsLiteralType
	breadcrumbs: ShellDimensionsLiteralType
}

export function useShellDimensions() {
	const themeContext = React.useContext(ThemeContext);

	const [shellDimensions, setShellDimensions] = React.useState<ShellDimensionsType>(
		{
			nav: {
				width: document.getElementById("nav").offsetWidth,
				height: document.getElementById("nav").offsetHeight,
			},
			content: {
				width: document.getElementById("content").offsetWidth,
				height: document.getElementById("content").offsetHeight,
			},
			menu: {
				width: document.getElementById("menu").offsetWidth,
				height: document.getElementById("menu").offsetHeight
			},
			breadcrumbs: {
				width: document.getElementById("breadcrumbs").offsetWidth,
				height: document.getElementById("breadcrumbs").offsetHeight
			}
		}
	);

	React.useEffect(() => {
		function handleResize() {
			const navElement = document.getElementById("nav");
			const menuElement = document.getElementById("menu");
			const contentElement = document.getElementById("content");
			const breadcrumbsElement = document.getElementById("breadcrumbs");

			setShellDimensions({
				nav: {
					width: navElement.offsetWidth,
					height: navElement.offsetHeight,
				},
				content: {
					width: contentElement.offsetWidth,
					height: contentElement.offsetHeight,
				},
				menu: {
					width: menuElement.offsetWidth,
					height: menuElement.offsetHeight,
				},
				breadcrumbs: {
					width: breadcrumbsElement.offsetWidth,
					height: breadcrumbsElement.offsetHeight,
				}
			});
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, [themeContext.menu]);

	return shellDimensions;
}