import React                                                     from "react";
import { BaseComponentPropType, ThemeContext, ThemeContextType } from "..";
import type { SpinnerComponentPropType }                         from ".";
import { CX, Styles }                                            from "./styles";
import { AiOutlineLoading3Quarters }                             from "react-icons/ai";

/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export function Spinner(props: SpinnerComponentPropType) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	const classes = CX(
		props.relative ? Styles["spinner-relative"] : Styles.spinner,
		Styles[themeContext.mode],
		props.className);

	return (
		<AiOutlineLoading3Quarters size={props.size ?? 26} className={classes}/>
	);
}