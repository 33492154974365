import type React               from "react";
import type { BreakpointsType } from "../../";

export enum ModeEnum {
    light = "light",
    dark = "dark",
}

export type ModeType = keyof typeof ModeEnum;

export interface ThemeContextType {
    mode: ModeType;
    setMode: React.Dispatch<React.SetStateAction<ModeType>>;
    breakpoint: BreakpointsType;
    menu: boolean;
    setMenu: React.Dispatch<React.SetStateAction<boolean>>;
    shelf: React.ReactNode;
    setShelf: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export interface ThemeContextTypeNoSet {
    mode?: ModeType;
}

export interface ThemePropType {
    mode?: ModeType;
    children: React.ReactNode;
}