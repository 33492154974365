import React                                                     from "react";
import { BaseComponentPropType, ThemeContext, ThemeContextType } from "..";
import { CX, Styles }                                            from "./styles";

/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export function Divider( props: BaseComponentPropType<HTMLDivElement> ) {
    const themeContext = React.useContext<ThemeContextType>( ThemeContext );

    const classes = CX(
        Styles[themeContext.mode],
        Styles.divider,
        props.className );

    // Create new mutable props object to delete unwanted props for prettier attributes
    const $props: BaseComponentPropType<HTMLDivElement> = Object.create( props );

    return (
        <div
            { ...$props }
            className={ classes }
            style={ { ...props.style } }
        >
            { props.children }
        </div>
    );
}