import React from "react";
import { Box, LinearProgress, Menu, MenuItem, MenuList } from "@mui/material";
import { DataGrid, DataGridProps, GridToolbarContainer } from "@mui/x-data-grid";
import { Button, ThemeContext, ThemeContextType } from "..";
import { CX, Styles } from "./styles";

//const useStyles = makeStyles( {
//    root: {
//        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
//            outline: "none"
//        }
//    }
//} );



/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export function Table(props: DataGridProps) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	//    const classes = CX( Styles.table, Styles[themeContext.mode], props.className );

	//    const $classes = useStyles;

	function TableEmptyComponent({ text }: { text?: string }) {
		const classesDiv = CX(Styles.tableNoContent, props.className);
		const classesH1 = CX(Styles.text, props.className);

		return <div className={classesDiv}><h1 className={classesH1}>{text ? text : "No data."}</h1></div>;
	}

	return (
		<DataGrid
			className={themeContext.mode}
			autoHeight
			checkboxSelection={true}
			slots={{
				loadingOverlay: () => <LinearProgress />,
				noResultsOverlay: () => <TableEmptyComponent text={"No results."} />,
				noRowsOverlay: () => <TableEmptyComponent text={"No data."} />,
				...props.slots
			}}
			{...props}
		/>
	);
}