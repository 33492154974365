import React from "react";

type WindowSizeType = {
	height: number;
	width: number;
}

export function useWindowSize() {
	const [windowSize, setWindowSize] = React.useState<WindowSizeType>({
		width: undefined,
		height: undefined,
	});

	React.useEffect(() => {
		function handleResize() {
			setWindowSize({
				height: window.innerHeight,
				width: window.innerWidth
			});
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
}