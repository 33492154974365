import React                    from "react";
import type { BreakpointsType } from "../../";
import { getBreakpoint }        from "../../";

export function useBreakpoint() {
	const [breakpoint, setBreakpoint] = React.useState<BreakpointsType>(null);

	React.useEffect(() => {
		function handleResize() {
			const $bp: BreakpointsType = getBreakpoint();
			setBreakpoint($bp);
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return breakpoint;
}