export interface Breakpoints<T> {
    xs: T;
    sm: T;
    md: T;
    lg: T;
    xl: T;
}

export enum BreakpointsEnum {
    xs = "xs",
    sm = "sm",
    md = "md",
    lg = "lg",
    xl = "xl"
}

export type BreakpointsType = keyof typeof BreakpointsEnum;