import React                                                                               from "react";
import { NavLink, Outlet, useNavigate, useLocation }                                       from "react-router-dom";
import { CX, Styles }                                                                      from "./styles";
import type { BaseComponentPropType, ThemeContextType, AuthContextType }                   from "..";
import { AuthContext, Button, LocalStorage, Spinner, Text, ThemeContext, Divider, Footer } from "..";
import { RiDashboard2Fill, RiOrganizationChart }                                           from "react-icons/ri";
import { BiLogOut, BiCog }                                                                 from "react-icons/bi";
import { CgMenuGridO }                                                                     from "react-icons/cg";
import type { MenuItemComponentPropType }                                                  from ".";
import cookie                                                                              from "js-cookie"
import { AiFillBug } from "react-icons/ai";
import { BsTable } from "react-icons/bs";
import { HiMoon, HiSun } from "react-icons/hi";

function Nav(props: BaseComponentPropType<HTMLDivElement>) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);
	const authContext = React.useContext<AuthContextType>(AuthContext);

	const navigate = useNavigate();

	const ref = React.useRef(null);
	const ref2 = React.useRef(null);

	const [navProfileIsActive, setNavProfileIsActive] = React.useState<boolean>(false);

	const classes = CX(
		Styles.nav,
		Styles[themeContext.breakpoint],
		(themeContext.menu ? Styles.active : null),
		Styles[themeContext.mode]
	);

	async function menuToggleHandler() {
		const state = !themeContext.menu;
		themeContext.setMenu(state);
		await LocalStorage.Forage.setItem("menu", state);
	}

	React.useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target) && !ref2.current.contains(event.target)) {
				setNavProfileIsActive(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	return <div id={"nav"} className={classes}>
		<Button onClick={menuToggleHandler} buttonType={"secondary"} variant={"transparent"}
		        className={CX(Styles.hamburger)}><CgMenuGridO size={30}/></Button>

		<div className={CX(Styles["nav-logo"])}/>

		<div ref={ref2} className={CX(Styles["nav-profile-icon-wrapper"])} onClick={() => setNavProfileIsActive(!navProfileIsActive)}>
			<div className={CX(Styles["nav-profile-icon"], Styles[themeContext.mode])}>
				<p>
					{
						authContext.data
							? authContext.data["email"].charAt(0).toUpperCase()
							: null
					}
				</p>
			</div>
		</div>
		<div ref={ref} className={CX(Styles["nav-profile-wrapper"], Styles[navProfileIsActive ? "active" : ""], Styles[themeContext.mode])}>
			<div className={CX(Styles["nav-profile-wrapper-header"])}>
				<div className={CX(Styles["nav-profile-icon"], Styles[themeContext.mode])}>
					<p>
						{
							authContext.data
								? authContext.data["email"].charAt(0).toUpperCase()
								: ""
						}
					</p>
				</div>
				<Text weight={500} size={".8rem"} style={{marginTop: 3}}>{authContext.data ? authContext.data["email"] : ""}</Text>

				<Divider style={{margin: "0.375rem 0 0.75rem 0"}}/>
			</div>
			<div className={CX(Styles["nav-profile-item-wrapper"])}>
				{/* <div className={CX(Styles["nav-profile-item"])} onClick={async () => {
					navigate("/user-settings", {
						replace: true
					});
					setNavProfileIsActive(false)
				}}>
					<p><BiCog/>Settings</p>
				</div> */}

				<div className={CX(Styles["nav-profile-item"])} onClick={async () => {
					themeContext.setMode(themeContext.mode === "light" ? "dark" : "light");
				}}>
					<p className={CX(Styles[themeContext.mode])}>{themeContext.mode === "light" ? <HiMoon/> : <HiSun/>}{`${themeContext.mode === "light" ? "Dark" : "Light"} Mode`}</p>
				</div>

				<div className={CX(Styles["nav-profile-item"])} onClick={async () => {
					cookie.remove("token");
					authContext.setAuthenticated(false);
					navigate("/login", {
						replace: true
					});
				}}>
					<p className={CX(Styles["logout"])}><BiLogOut/>Logout</p>
				</div>
			</div>
		</div>
	</div>;
}

function Menu(props: BaseComponentPropType<HTMLDivElement>) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);
	const authContext = React.useContext(AuthContext);
	const location = useLocation();

	const classes = CX(
		Styles.menu,
		Styles[themeContext.breakpoint],
		(themeContext.menu ? Styles.active : null),
		Styles[themeContext.mode]);

	React.useEffect(() => {
		authContext.setValidate(!authContext.validate)
	}, [location])

	const buttons = React.useMemo(() => {
		console.log("location change");
		const $ = [
			{
				children: <p>Dashboard</p>,
				path: "/dashboard",
				focused: location.pathname === "/dashboard",
				icon: <RiDashboard2Fill/>
			},
			{
				children: <p>Admins</p>,
				path: "/admins",
				focused: location.pathname === "/admins",
				icon: <BsTable/>
			},
			{
				children: <p>Bug Reports</p>,
				path: "/bug-reports",
				focused: location.pathname === "/bug-reports",
				icon: <AiFillBug/>
			},
			{
				children: <p>Logs</p>,
				path: "/logs",
				focused: location.pathname === "/logs",
				icon: <BsTable/>
			},
			{
				children: <p>Accounts</p>,
				path: "/accounts",
				focused: location.pathname === "/accounts",
				icon: <BsTable/>
			},
		]

		console.log($);

		return $.map(($, i) => {
			return <NavLink className={$.focused ? Styles["disabled"] : null} to={$.path} key={i}>
				<MenuItem
					key={i}
					// style={{marginTop: i === 0 ? "1rem" : ""}}
					variant={"transparent"}
					buttonType={"secondary"}
					toggle
					disableRipple
					focused={$.focused}
					id={`menuitem${i}`}
					icon={$.icon}
					children={$.children}/>
			</NavLink>;
		})

	}, [location])

	return <div id={"menu"} {...props} className={classes}>
		{/*<MenuProfile/>*/}

		{
			buttons
		}

		{/*<Footer style={{position: "absolute", bottom: 0}}/>*/}

		{/* Logout Button */}
		{/*<MenuItem*/}
		{/*	className={Styles["logout"]}*/}
		{/*	variant={"transparent"}*/}
		{/*	buttonType={"secondary"}*/}
		{/*	id={`menuitemlogout`}*/}
		{/*	toggle*/}
		{/*	onClick={async () => {*/}
		{/*		await LocalStorage.setAuthToken(null);*/}
		{/*		authContext.setAuthenticated(false);*/}
		{/*		navigate("/", {*/}
		{/*			replace: true*/}
		{/*		});*/}
		{/*	}}*/}
		{/*	icon={<BiLogOut/>}*/}
		{/*	children={<p>Logout</p>}/>*/}
		{props.children}
	</div>;
}

function MenuItem(props: MenuItemComponentPropType) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	const classes = CX(Styles.item, Styles[themeContext.mode], props.className, props.focused ? Styles.focused : "");

	return <Button {...props} id={props.id} style={{...props.style}} className={classes} icon={props.icon} variant={"transparent"} buttonType={"secondary"}>{props.children}</Button>;
}

function Content(props: BaseComponentPropType<HTMLDivElement>) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	const classes = CX(
		Styles.content,
		Styles[themeContext.breakpoint],
		(themeContext.menu ? Styles.active : null),
		themeContext.mode
	);

	return <div id={"content"} className={classes}>{props.children}</div>;
}

function Breadcrumbs(props: BaseComponentPropType<HTMLDivElement>) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	const location = useLocation();

	const classes = CX(Styles.breadcrumbs, (themeContext.menu ? Styles.active : null), Styles[themeContext.mode]);

	return <div {...props} className={classes} id={"breadcrumbs"}>
		{
			location.pathname.split("/").slice(1).map((crumb) => 
				<NavLink 
					to={`/${crumb}`} 
					className={CX(Styles.breadcrumbs, Styles[themeContext.mode])}
					key={crumb}
				>
					{crumb}
				</NavLink>
			)
		}
		{/*<a href={"#"} className={CX(Styles.breadcrumbs, Styles[themeContext.mode])}>AWS</a>*/}
		{/*<a href={"#"} className={CX(Styles.breadcrumbs, Styles[themeContext.mode])}>EC2</a>*/}
		{/*<a href={"#"} className={CX(Styles.breadcrumbs, Styles[themeContext.mode])}>Instance</a>*/}
	</div>;
}

export function AuthShell({children}: { children? }): JSX.Element {
	// An <Outlet> should be used in parent route elements to render their child route elements. This allows nested UI
	// to show up when child routes are rendered. If the parent route matched exactly, it will render a child index
	// route or nothing if there is no index route.
	const Proxy = () => (children) ?? (<Outlet/>);

	// const themeContext = React.useContext(ThemeContext);

	return (
		<div>
			<Nav/>
			<Menu/>
			<Breadcrumbs/>
			<Content>
				<React.Suspense fallback={<Spinner/>}>
					<Proxy/>
					<Footer/>
				</React.Suspense>
			</Content>
			{/*{*/}
			{/*	themeContext.shelf*/}
			{/*}*/}
		</div>
	);
}

export function PublicShell({children}: { children? }): JSX.Element {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	const classes = CX(Styles.shell, Styles[themeContext.mode]);

	// An <Outlet> should be used in parent route elements to render their child route elements. This allows nested UI
	// to show up when child routes are rendered. If the parent route matched exactly, it will render a child index
	// route or nothing if there is no index route.
	const Proxy = () => (children) ?? (<Outlet/>);

	return (
		<div className={classes}>
			<React.Suspense fallback={<Spinner/>}>
				<Proxy/>
			</React.Suspense>
		</div>
	);
}