import React                              from "react";
import { ThemeContext, ThemeContextType } from "..";
import type { InputComponentPropType }    from ".";
import { CX, Styles }                     from "./styles";

/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export const Input = React.forwardRef(function Input(props: InputComponentPropType, ref) {
	const themeContext = React.useContext<ThemeContextType>(ThemeContext);

	const classes = CX(
		Styles.input,
		props.icon ? Styles.icon : null,
		props.disabled ? Styles.disabled : null,
		props.className, Styles[themeContext.mode]
	);

	// Create new mutable props object to delete unwanted props for prettier attributes
	const $props: InputComponentPropType = Object.assign({}, props);
	delete $props.icon;
	delete $props.style;
	delete $props.label;

	return (
		<div className={CX(Styles["wrapper"])} style={{...props.style}}>
			{
				props.label
					? <label className={CX(Styles[themeContext.mode])}>{props.label}</label>
					: null
			}
			<div className={Styles["input-wrapper"]} style={
				{
					// ...props.style
				}
			}>
				{/* Icon */}
				{
					props.icon
						? <div className={CX(Styles["icon-wrapper"], Styles[themeContext.mode])}>
							{props.icon}
						</div>
						: null
				}

				<input
					ref={ref as React.LegacyRef<HTMLInputElement>}
					{...$props}
					className={classes}
				/>
			</div>
		</div>
	);
});