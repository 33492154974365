import React                          from "react";
import { ThemeContext }               from "../../";
import type { ThemeContextType }      from "../../";
import type { TextComponentPropType } from ".";
import { CX, Styles }                 from "./styles";

/***
 * Header
 * @param {BaseComponentPropType<HTMLHeadingElement>} props
 * @returns {JSX.Element}
 * @constructor
 */
export function Text( props: TextComponentPropType ) {
    const themeContext = React.useContext<ThemeContextType>( ThemeContext );

    const classes = CX(
        Styles[themeContext.mode],
        Styles.header, props.className
    );

    // Create new mutable props object to delete unwanted props for prettier attributes
    const $props: TextComponentPropType = Object.assign( {}, props );
    delete $props.padding;
    delete $props.background;
    delete $props.color;
    delete $props.weight;
    delete $props.size;

    return (
        <p
            { ...$props }
            className={ classes }
            style={
                {
                    padding: props.padding,
                    background: props.background,
                    color: props.color,
                    fontWeight: props.weight,
                    fontSize: typeof props.size === "object" ? props.size[themeContext.breakpoint] : props.size,
                    ...props.style
                }
            }
        >
            { props.children }
        </p>
    );
}