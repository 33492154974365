import React                              from "react";
import style                              from "./style";
import SyntaxHighlighter                  from "react-syntax-highlighter";
// import { atelierCaveLight as Style } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ThemeContext, Button, useEvent } from "..";
import { Styles, CX }                     from "./styles";
import { FaCopy }                         from "react-icons/fa";
import { AiFillCheckCircle }              from "react-icons/ai";

export const Code = ({language, ...props}) => {
	const themeContext = React.useContext(ThemeContext);
	const event = useEvent();

	return <div className={CX(Styles.code)} style={props.style}>
		<div className={CX(Styles["button-container"])}>
			<Button
				size={"sm"}
				buttonType={"secondary"}
				variant={"outlined"}
				onClick={() => {
					navigator.clipboard.writeText(props.children);
					event({
						action: "add",
						options: {
							eventTitle: "Success",
							expiresIn: 3000,
							type: "success",
							eventIcon: <AiFillCheckCircle color={"#16ff00"}/>,
							children: "Code copied successfully"
						}
					})
				}}
			><FaCopy/></Button>
		</div>
		<SyntaxHighlighter
			className={themeContext.mode}
			style={style}
			language={language}
			PreTag="pre"
			{...props}
		>
			{props.children}
		</SyntaxHighlighter>
	</div>;
};
