import * as Forage from "localforage";

Forage.config({
	driver: Forage.INDEXEDDB,
	name: "DigiTrakAdmin",
	version: 1.0,
	storeName: "storage",
	description: "..."
});

export { Forage };
